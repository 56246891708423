<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form label-width="7rem" style="padding: 2rem;width: 30rem">
          <el-form-item label="站点logo" style="width: 30rem">
            <y_upload_img v-model="form.site_logo"></y_upload_img>
          </el-form-item>
          <el-form-item label="底部版权">
            <el-input v-model="form.copyright"></el-input>
          </el-form-item>
          <el-form-item label="icp备案号">
            <el-input v-model="form.icp"></el-input>
          </el-form-item>
          <el-form-item label="登陆页侧边banner" style="width: 30rem">
            <y_upload_img v-model="form.login_banner"></y_upload_img>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="12">
        <el-form label-width="7rem" style="padding: 2rem;width: 30rem">
          <el-form-item label="登陆页背景图片" style="width: 30rem">
            <y_upload_img v-model="form.index_bg_img"></y_upload_img>
          </el-form-item>
          <el-form-item label="登陆窗口标题">
            <el-input v-model="form.index_title"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="submit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import y_upload_img from "@/components/y_upload_img";
export default {
  name: "site",
  components:{
    y_upload_img
  },
  data(){
    return{
      form:{
        copyright:"",
        icp:"",
        site_logo:"",
        login_banner:"",
        index_bg_img:"",
        index_title:""
      }
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    load(){
      this.$api.manager.systemFetch().then(res=>{
        this.form = res;
      })
    },
    submit(){
      this.$api.manager.systemUpdate(this.form).then(()=>{
        this.$message.success("操作成功");
      })
    }
  }
}
</script>

<style scoped>

</style>